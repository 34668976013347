import ng from 'angular';

/*
  I display the user's timezone code (ex. CST).
  This does not put a space suffix so you need to make sure the passed
  through string has it spaced the way you want the timezone to display.
  Generally, this means having a &nbsp; at the end of your inputted string.
*/
export function acpShowTimezoneFilter($window) {
  'ngInject';
  return function(str: string = '') {
    let timezoneStr;
    try {
      timezoneStr = $window
        .Date()
        .toString()
        .match(/\(([A-Za-z\s].*)\)/)[1];
    } catch (err) {
      // Do nothing
    }

    return str + timezoneStr;
  };
}

const ngModule = ng
  .module('acp.shared.filters.acpShowTimezoneFilter', [])
  .filter('acpShowTimezone', acpShowTimezoneFilter);

export default ngModule;
